<template>
    <div>
        <h1>Workout App</h1>

        Todo:
        <ul>
            <li><s>В списке всех тренировок сделать кнопку у каждого упражнения "запланировать на сегодня"</s></li>
            <li><s>На странице выполнения упражнения выводить максильно лучший твой результат по данному упражнению</s></li>
            <li><s>При записи результатов под полями ввода маленькими тегами выводить предыдущие результаты для быстрой подстановки</s></li>
            <li><s>На странице выполнения тренировки отмечать с каким весом по факту выполнялось упражнение и сколько повторений</s></li>
            <li><s>Создание / редактирование упражнений на странице тренировочных планов (не работает)</s></li>
            <li><s>При создании тренировки отдельная кнопка "выбрать из готовых"</s></li>
        </ul>

        Wishlist:
        <ul>
            <li>Добавить возможность делиться тренировками с другими пользователями</li>
            <li>В админке в упражнениях фильтр по части тела</li>
            <li>В админке в упражнениях поиск по упражнениям</li>
            <li>Роли "тренер" и "тренируемый" чтобы один мог составлять планы для другого</li>
            <li>Для упражнения указать целевую группу мышц</li>
            <li><s>Кнопку запуска тренировки на отдельной страничке со списком упражнений (чтобы было видно всё получше и покрупнее) с таймером и всеми пирогами</s></li>
            <li><s>Количество повторений указывать интервалом (скорее всего просто текстовое поле)</s></li>
            <li><s>Комментарий ко всему сету</s></li>
            <li><s>Количество подходов в сете</s></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Main",
    mounted() {
    },
}
</script>

<style scoped>

</style>
