<template>
    <div>
        <h1>Workout App</h1>

        <Calendar></Calendar>

    </div>
</template>

<script>
import Calendar from "../components/calendar/Calendar";

export default {
    name: "CalendarPage",
    components: {Calendar}
}
</script>

<style scoped>

</style>
