import axios from "axios";
import {commit} from "lodash/seq";

export default {
    state: () => ({
    }),
    getters: {},
    mutations: {},
    actions: {},
    namespaced: true
}
